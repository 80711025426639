import React from "react";
import PropTypes from 'prop-types';
import Translator from "./Translator";
import LoadingScreen from "../widget/LoadingScreen";
import LoadingModal from "../widget/LoadingModal";


export default class TranslationsProvider extends React.Component {
    render() {
        const {children, loadingScreen, loadingModal} = this.props;
        const {initialized} = this.state || {};

        if (!initialized) {
            if (loadingScreen) {
                return <LoadingScreen/>;
            }
            if (loadingModal) {
                return <LoadingModal/>;
            }
        }

        return children(Translator.languageCode);
    }

    async componentWillMount() {
        const {languageCode} = this.props;

        if (languageCode) {
            await Translator.setLanguageCode(languageCode);
        }

        await Translator.ensureLanguage(Translator.getLanguageCode());
        await Translator.ensureLanguage(Translator.fallbackLocale);

        Translator.getEventEmitter().on(Translator.EVENTS_TRANSLATIONS_RECEIVED, () => this.forceUpdate());

        this.setState({initialized: true});
    }
}

TranslationsProvider.propTypes = {
    children: PropTypes.func.isRequired,
    loadingScreen: PropTypes.bool,
    loadingModal: PropTypes.bool,
    languageCode: PropTypes.string,
};
