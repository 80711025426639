import "babel-polyfill";
import {loadCSS} from 'fg-loadcss';
import React from "react";
import ReactDOM from 'react-dom';
import AuthScreen from "../views/AuthScreen";
import AnchorDiv from "../utils/AnchorDiv";


loadCSS('https://fonts.googleapis.com/css?family=Roboto:400,500');
loadCSS('https://fonts.googleapis.com/css?family=Material+Icons');

window.openAuthScreen = (props) => ReactDOM.render(
    <AuthScreen {...props}/>,
    AnchorDiv.attachDivToBody()
);