import React from "react";
import {CircularProgress} from "@material-ui/core";

export default function LoadingScreen({style, white, position}) {
    return <div
        style={{
            position: position || 'absolute',
            left: '0',
            top: '0',
            display: 'flex',
            flex: '1',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            background: white ? '#FFF' : 'rgb(236, 236, 236)',
            ...style
        }}>
        <CircularProgress/>
    </div>;
}