import EventEmitter from "events";
import {translation} from "./Translation";

export const languages = [
    ['nb', 'Norsk'],
    ['en', 'English'],
    ['de', 'Deutsch'],
    ['sv', 'Svenska'],
    ['vi', 'Tiếng Việt'],
    ['nl', 'Nederlands']
];

export default class Translator {
    static languages = {};
    static languageCode = 'nb';
    static fallbackLocale = 'en';

    static EVENTS_TRANSLATIONS_RECEIVED = 'translations_received';

    static getEventEmitter(): EventEmitter {
        if (!Translator.eventEmitter)
            Translator.eventEmitter = new EventEmitter();

        return Translator.eventEmitter;
    }

    static async ensureLanguage(language) {
        if (Translator.languages[language])
            return Translator.languages[language];

        try {
            let cached = JSON.parse(localStorage.getItem('Translator-Language-' + language + '-' + process.env.APP_VERSION));

            if (cached) {
                Translator.languages[language] = cached;
                if (!process.env.APP_VERSION)
                    Translator.loadLanguage(language).then();
                
                return cached;
            }
        } catch (e) {
            
        }

        return await Translator.loadLanguage(language);
    }

    static async loadLanguage(language) {
        const response = translation[language];
        Translator.languages[language] = response;

        try {
            for (const key in localStorage) {
                if (key.startsWith('Translator-Language-' + language) && key !== 'Translator-Language-' + language + '-' + process.env.APP_VERSION) {
                    localStorage.removeItem(key);
                }
            }

            localStorage.setItem('Translator-Language-' + language + '-' + process.env.APP_VERSION, JSON.stringify(response));
        } catch (e) {
        }

        return response;
    }

    static async setLanguageCode(language) {
        return this.ensureLanguage(language).then(r =>{
            this.languageCode = language;
            localStorage.setItem('LanguageCode', JSON.stringify(language));

            Translator.getEventEmitter().emit(Translator.EVENTS_TRANSLATIONS_RECEIVED)
        });
    }

    static getLanguageCode() {
        try {
            let cached = JSON.parse(localStorage.getItem('LanguageCode'));
            let cachedLanguage = null;

            if (cached) {
                let supportLanguages = languages.map(language => language[0]);

                cachedLanguage = supportLanguages.includes(cached) ? cached : null;
            }

            if (cachedLanguage)
                this.languageCode = cached;

        } catch (e) {
        }

        return this.languageCode;
    }

    static trans(id, parameters, domain, locale) {
        let internalLocale = locale ? locale : this.languageCode;
        const fallbackLocale = this.fallbackLocale;

        if (!Translator.languages[internalLocale]) {
            if (internalLocale.length === 5) {
                internalLocale = internalLocale.substr(0, 2);
                if (!Translator.languages[internalLocale]) {
                    return domain + '.' + id;
                }
            } else if (internalLocale.length === 2) {

                for (let lo of Object.keys(Translator.languages)) {
                    if (lo.substr(0, 2) === internalLocale) {
                        internalLocale = lo;
                        break;
                    }
                }

                if (!Translator.languages[internalLocale]) {
                    return domain + '.' + id;
                }

            } else {
                return domain + '.' + id;
            }
        }

        var string = domain + '.' + id;
        if (domain) {
            if (Translator.languages[internalLocale][domain] && Translator.languages[internalLocale][domain][id])
                string = Translator.languages[internalLocale][domain][id];
            else if (Translator.languages[fallbackLocale] && Translator.languages[fallbackLocale][domain] && Translator.languages[fallbackLocale][domain][id])
                string = Translator.languages[fallbackLocale][domain][id];
            else
                return domain + '.' + id;
        } else {
            return domain + '.' + id;
        }

        if (parameters) {
            for (let key of Object.keys(parameters)) {
                string = string.replace('%' + key + '%', parameters[key]);
            }
        }

        return string;
    }
}
