import React from "react";
import * as PropTypes from "prop-types";
import supportsWebP from 'supports-webp';
import jsonp from "jsonp";
import queryString from "query-string";

import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {AppBar, IconButton, InputBase, Toolbar} from "@material-ui/core";
import TranslationsProvider from "../utils/internationalization/TranslationsProvider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Translator from "../utils/internationalization/Translator";
import {languages} from "../utils/internationalization/Translator";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const isMobile = window.innerWidth < 768;

export default class AuthScreen extends React.Component {
    static propTypes = {
        authenticationDomains: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    state = {open: false};

    render() {
        const {
            onSelect,
            authenticationDomains,
        } = this.props;

        const source = queryString.parse(location.search).source || 'web';

        return <TranslationsProvider loadingModal>
            {languageCode =>  <Dialog open={this.state.open} fullScreen>
                <div style={{position: 'relative', width: '100%', height: '100%',}}>
                    <Background/>

                    <Foreground  languageCode={languageCode}>
                        <Logo/>
                        <SearchBox {...{onSelect, authenticationDomains, source}} languageCode={languageCode}/>
                        <Spacing/>
                    </Foreground>
                </div>
            </Dialog>}

            </TranslationsProvider>
    }

    componentDidMount() {
        this.setState({open: true});
    }
}

class Background extends React.PureComponent {
    render() {
        return <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #effaff 100% )',
        }} aria-hidden>
            <div style={{
                position: 'absolute',
                width: '100%',
                bottom: '0',
                height: '100%',
                background: 'url(/img/background-desktop' + (supportsWebP ? '.webp' : '.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
                backgroundPositionY: 'bottom'
            }}/>
        </div>;
    }
}

class Foreground extends React.PureComponent {
    render() {

        let padding = '50px';

        if (window.innerWidth < 400)
            padding = '0 30px';

        return <div style={{
            display: 'flex',
            alignItems: 'stretch',
            flexFlow: 'column',
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflowY: 'hidden',
            zIndex: '100',
            padding,
            boxSizing: 'border-box'
        }}>
            <div style={{
                display: 'flex',
                marginTop: isMobile ? '20px' : 0,
                justifyContent: 'flex-end',
            }}>
                <FormControl style={{minWidth: '140px'}}>
                    <InputLabel>{Translator.trans('change-language', null, 'auth_portal')}</InputLabel>
                    <Select
                        fullWidth
                        value={this.props.languageCode}
                        onChange={event => Translator.setLanguageCode(event.target.value)}
                    >
                        {languages.map((language, id) => {
                            return <MenuItem  id={id} value={language[0]}>
                                {language[1]}
                            </MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </div>
            {this.props.children}

        </div>;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.languageCode !== this.props.languageCode;
    }
}

class Logo extends React.PureComponent {
    render() {
        return <div
            style={{
                flex: '2',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
            }}>
            <img src={'/img/logo' + (supportsWebP ? '.webp' : '.png')} style={{
                width: '60%',
                maxWidth: '220px',
                maxHeight: '100%',
                height: 'auto',
                objectFit: 'contain',
            }} alt={'SchoolLink logo'}/>
        </div>;
    }
}

class SearchBox extends React.PureComponent {
    render() {

        let padding = '50px';

        if (window.innerWidth < 400)
            padding = '30px';

        return <div style={{
            flexShrink: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Paper elevation={8} style={{padding, flex: '1', maxWidth: '260px'}}>
                <div style={{marginBottom: '30px'}}>
                    <Typography variant={'title'}>{Translator.trans('find_unit_hint', null, 'auth_portal')}</Typography>
                </div>

                <SearchField {...this.props}/>
            </Paper>
        </div>;
    }
}

class Spacing extends React.PureComponent {
    render() {
        return <div style={{flex: '3'}}/>;
    }
}

class SearchField extends React.PureComponent {
    state = {query: '', open: false, schools: []};

    handleClose = () => this.setState({open: false});

    render() {
        const {source} = this.props;
        const {query, open} = this.state;

        return <div style={{position: 'relative', width: '100%'}}>
            <TextField
                label={Translator.trans('unit_types', null, 'auth_portal')}
                autoFocus={!isMobile}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position="end"><Icon>search</Icon></InputAdornment>,
                }}
                value={this.state.query}
                onChange={event => {
                    this.setState({
                        query: event.target.value
                    });
                }}
                onFocus={() => this.setState({open: true})}
            />

            {open
                ? <SearchResult
                    query={query}
                    schools={this.state.schools}
                    onClose={this.handleClose}
                    source={source}
                    languageCode={this.props.languageCode}
                />
                : null}
        </div>;
    }

    async componentDidMount() {
        this.props.authenticationDomains.forEach(async authenticationDomain => {
            const retrievedSchools = (await new Promise(solve => jsonp('https://' + authenticationDomain + '/Api/Web/Authentication/GetAuthSchools', {}, (err, data) => solve(data))))
                .map(school => ({authenticationDomain, ...school}));

            this.setState(({schools}) => ({schools: [...retrievedSchools, ...schools]}));
        });
    }

}

class SearchResult extends React.PureComponent {
    render() {
        if (!isMobile) {
            return <SearchResultDesktop {...this.props}/>;
        } else {
            return <SearchResultMobile {...this.props}/>;

        }
    }
}

const schoolFilter = query => ({schoolName}) => schoolName.toLocaleLowerCase().includes(query.toLocaleLowerCase());

const schoolSort = (a, b) => a.schoolName.localeCompare(b.schoolName);

class SearchResultDesktop extends React.PureComponent {
    render() {
        const {query, schools} = this.props;
        const startSearch = query && query.length > 1;

        const filteredSchools = startSearch ? schools.filter(schoolFilter(query)).sort(schoolSort) : [];

        return <Paper style={{position: 'absolute', width: '100%', maxHeight: '250px', overflowY: 'scroll'}}
                      elevation={3}>
            {filteredSchools.length > 0
                ? <List>
                    {filteredSchools.map(school => {
                        return <SchoolListItem
                            key={school.authenticationDomain + school.schoolId}
                            {...this.props}
                            school={school}
                        />
                    })}
                </List>
                : startSearch && <div style={{padding: '20px'}}>
                    <Typography>{Translator.trans('search_not_found', null, 'auth_portal')}</Typography>
                </div>}
        </Paper>;
    }
}

const styles = {
    mobileInput: {
        color: 'white'
    },
    mobileRows: {width: '100%', height: '100%', display: 'flex', alignItems: 'stretch', flexFlow: 'column'}
};

class SearchResultMobile extends React.PureComponent {
    state = {query: ''};

    handleInputChange = e => this.setState({query: e.target.value});

    render() {
        const {query} = this.state;
        const {schools, onClose} = this.props;
        const startSearch = query && query.length > 1;

        const filteredSchools = startSearch ? schools
            .filter(schoolFilter(query))
            .sort(schoolSort) : [];

        return <Dialog open fullScreen onClose={onClose}>
            <div style={styles.mobileRows}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton color="inherit" onClick={onClose}>
                            <Icon>keyboard_arrow_left</Icon>
                        </IconButton>

                        <InputBase
                            autoFocus
                            placeholder={Translator.trans('search_for_unit', null, 'auth_portal')}
                            style={styles.mobileInput}
                            value={query}
                            onChange={this.handleInputChange}
                        />
                    </Toolbar>
                </AppBar>

                <div style={{flex: '1', overflowY: 'auto'}}>
                    {filteredSchools.length > 0
                        ? <List>
                            {filteredSchools.map(school => {
                                return <SchoolListItem
                                    key={school.authenticationDomain + school.schoolId}
                                    {...this.props}
                                    school={school}
                                />
                            })}
                        </List>
                        : startSearch && <div style={{padding: '20px'}}>
                            <Typography>{Translator.trans('search_not_found', null, 'auth_portal')}</Typography>
                        </div>}
                </div>
            </div>
        </Dialog>;
    }
}

class SchoolListItem extends React.PureComponent {
    render() {
        const {school, source} = this.props;
        const {schoolId, schoolName, schoolOwnerName, authenticationDomain} = school;

        let {languageCode} = this.props || school;

        const url = 'https://' + authenticationDomain + '/?hl=' + languageCode + '&source=' + source;

        return <ListItem button key={authenticationDomain + schoolId} component={'a'}
                         href={url}>
            <ListItemText
                primary={schoolName}
                secondary={schoolOwnerName}
            />
        </ListItem>;
    }
}
