export const translation = {
    'en': {
        auth_portal: {
            'change-language': "Change language",
            'find_unit_hint': "Find your school/kindergarten",
            'unit_types': "School/Kindergarten",
            'search_for_unit': "Search for school/kindergarten",
            'search_not_found': "No schools/kindergartens found",
        }
    },
    'nb': {
        auth_portal: {
            'change-language': "Endre språk",
            'find_unit_hint': "Finn din skole/barnehage",
            'unit_types': "Skole/barnehage",
            'search_for_unit': "Søk etter skole/barnehage",
            'search_not_found': "Ingen skoler/barnehager funnet",
        }
    },
    'sv': {
        auth_portal: {
            'change-language': "Ändra språk",
            'find_unit_hint': "Hitta din skola / daghem",
            'unit_types': "Skola / Daghem",
            'search_for_unit': "Sök efter skola / daghem",
            'search_not_found': "Inga skolor / daghem hittades",
        }
    },
    'de': {
        auth_portal: {

        }
    },
    'nl': {
        auth_portal: {

        }
    },
    'vi': {
        auth_portal: {
            'change-language': "Thay đổi ngôn ngữ",
            'find_unit_hint': "Tìm kiếm trường của bạn",
            'unit_types': "Trường/Trường mầm non",
            'search_for_unit': "Tìm kiếm trường",
            'search_not_found': "Không tìm thấy",
        }
    },
};